import React from "react";
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import './index.scss';

const { Title, Text } = Typography;

/**
 * It returns a div with a title, a text, a link and an icon
 * @returns A React component
 */

const CongratsTicket = ({
							icon,
							title,
							linkText,
							linkHref,
							messageText
						}) => {
	const translate = useTranslation();

	return (
		<div className="success-message-content">
			<div>
				<Title level={3}>{translate(title)}</Title>
				<Text>{translate(messageText)}</Text>
				{
					linkText && (
						<div className="link-content">
							<Link to={linkHref}>{translate(linkText)}</Link>
						</div>
					)
				}
				<div className="icon-content">
					<img src={icon} alt=""/>
				</div>
			</div>
		</div>
	);
};

export default CongratsTicket;