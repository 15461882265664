import { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { formats, modules } from './utils';
import 'react-quill/dist/quill.snow.css';
import useTranslation from 'hooks/useTranslation';
import './index.scss';
import { RichText } from '../index';

const Editor = ({
					value = '<p></p>',
					hideEditor = true,
					className = '',
					style,
					onChange,
					disabled,
					placeholder
				}) => {
	const [ editorState, setEditorState ] = useState(null);

	useEffect(() => {
		if(value !== editorState) {
			setEditorState(value);
		}
	}, [ value ]);

	const translate = useTranslation();

	const handleEditorChange = useCallback(async editorState => {
		await setEditorState(editorState);
		onChange(editorState || '');
	}, []);

	return (
		<div className="base_react_editor_container">
			{
				hideEditor ? <ReactQuill
					theme="snow"
					modules={modules}
					className={`${className} ${disabled ? 'disabled_editor' : ''}`}
					style={style}
					formats={formats}
					value={editorState}
					onChange={handleEditorChange}
					placeholder={translate(placeholder)}
				/> : (
					<RichText
						className="editor_alt_rich_text"
						text={editorState}
					/>
				)
			}
		</div>
	);
};

export default Editor;