import Fetch from '../fetch';
import { CONTENT_TYPES } from '../../../constants/apiConstants/contentTypes';

const END_POINTS = {
	news: 'news',
	users: 'users',
	admin: 'admin',
	status: 'status',
	import: 'import',
	reorder: 'reorder',
	content: 'content',
	courses: 'courses',
	history: 'history',
	sections: 'sections',
	messages: 'messages',
	prefix: 'landingPage',
	comments: 'comments',
	menuItems: 'menuItems',
	responses: 'responses',
	proposals: 'proposals',
	visibility: 'visibility',
	broadcast: 'broadcast',
	subscribers: 'subscribers',
	instructors: 'instructors',
	configurations: 'configurations'
};

class LandingPageApiHandler {
	async getMenuItems(filter) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.menuItems}`, filter);
	}

	async updateMenuItems(data) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.menuItems}`, data);
	}

	async getSections(filter) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.sections}`, filter);
	}

	async getSubscribers(filter) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.news}/${END_POINTS.subscribers}`, filter);
	}

	async getMessages(filter) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.messages}`, filter);
	}

	async getMessage(id) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.messages}/${id}`);
	}

	async getNewsHistory(filter) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.news}/${END_POINTS.history}`, filter);
	};

	async getNewsHistoryUsers(id) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.news}/${END_POINTS.history}/${id}/${END_POINTS.users}`);
	}

	async sendMessageComments({ id, data }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.messages}/${id}/${END_POINTS.comments}`, data);
	}

	async editMessageResponses({ id, data }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.messages}/${id}/${END_POINTS.responses}`, data);
	}

	async changeMessageStatus({ id, data }) {
		return Fetch.patch(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.messages}/${id}/${END_POINTS.status}`, data);
	}

	async isShowLandingPageSection({ id, isVisible }) {
		return Fetch.patch(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.sections}/${id}/${END_POINTS.visibility}?isVisible=${isVisible}`);
	}

	async reorderSections(data) {
		return Fetch.put(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.sections}/${END_POINTS.reorder}`, data);
	}

	async addBroadcast(data) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.news}/${END_POINTS.broadcast}`, data);
	}

	async getProposals(languageId) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.proposals}/${languageId}`);
	}

	async getConfigurations() {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.configurations}`);
	}

	async getCourses({ languageId }) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.courses}/${languageId}`);
	}

	async addCourse({ languageId, data }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.courses}/${languageId}`, data);
	}

	async addInstructor({ languageId, data }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.instructors}/${languageId}`, data);
	}

	async addProposals({ languageId, formData }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.proposals}/${languageId}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}

	async editProposals({ id, formData }) {
		return Fetch.put(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.proposals}/${id}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}

	async addSectionContent({ sectionId, languageId, formData }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.sections}/${sectionId}/${END_POINTS.content}?languageId=${languageId}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}

	async importImportSubscribers(formData) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.news}/${END_POINTS.subscribers}/${END_POINTS.import}`, formData, { contentType: CONTENT_TYPES.FORM_DATA });
	}

	async getSectionContent({ sectionId, languageId }) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.sections}/${sectionId}/${END_POINTS.content}?languageId=${languageId}`);
	}

	async sendComment({ id, comment }) {
		return Fetch.post(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.messages}/${id}/${END_POINTS.comments}`, comment);
	}

	async deleteProposals(id) {
		return Fetch.delete(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.proposals}/${id}`);
	}

	async proposalsReorder({ languageId, data }) {
		return Fetch.patch(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.proposals}/${languageId}/${END_POINTS.reorder}`, { bannerOrders: data });
	}

	async getInstructors(languageId) {
		return Fetch.get(`${END_POINTS.prefix}/${END_POINTS.admin}/${END_POINTS.instructors}/${languageId}`);
	}
}

const landingPageApiHandler = new LandingPageApiHandler();
export default landingPageApiHandler;
